<template>
  <div class="container">
    <div class="main">
      <van-form @submit="onSubmit">
        <van-field name="invoiceType" label="发票类型">
          <template #input>
            <van-radio-group v-model="invoiceType" direction="horizontal">
              <van-radio name="1">个人</van-radio>
              <van-radio name="2">公司</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="invoiceTitle"
          name="invoiceTitle"
          label="发票抬头"
          placeholder="请填写发票抬头"
          :rules="[{ required: true, message: '发票抬头不能为空' }]"
        />
        <van-field
          readonly
          clickable
          name="payment_title"
          :value="payment_title"
          label="发票内容"
          placeholder="点击选择发票内容"
          right-icon="arrow"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-if="invoiceType === '2'"
          v-model="dutyNo"
          name="dutyNo"
          label="纳税人识别号"
          placeholder="请填写纳税人识别号"
          :rules="[{ required: true, message: '纳税人识别号不能为空' }]"
        />
        <van-field
          v-model="bank"
          name="bank"
          label="开户银行"
          placeholder="请填写开户银行"
          :rules="[{ required: true, message: '开户银行不能为空' }]"
        />
        <van-field
          v-model="bankAccount"
          name="bankAccount"
          label="银行账号"
          placeholder="请填写银行账号"
          :rules="[{ required: true, message: '银行账号不能为空' }]"
        />
        <van-field
          v-model="telphone"
          name="telphone"
          label="联系电话"
          placeholder="请填写联系电话"
          :rules="[{ required: true, message: '联系电话不能为空' }]"
        />
        <van-field
          v-model="address"
          name="address"
          type="textarea"
          rows="2"
          autosize
          label="邮递地址"
          placeholder="请填写邮递地址"
          :rules="[{ required: true, message: '邮递地址不能为空' }]"
        />
        <div style="margin: 16px;">
          <van-button block type="primary" native-type="submit">
            提交申请
          </van-button>
        </div>

      </van-form>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Tab, Tabs, Form, Field, Radio, RadioGroup, Picker } from 'vant'

export default {
  name: 'InvoiceSubmit',
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Picker.name]: Picker
  },
  mixins: [PageMixin],
  data() {
    return {
      invoiceType: '1',
      invoiceTitle: '',
      dutyNo: '',
      bank: '',
      bankAccount: '',
      telphone: '',
      address: '',
      showPicker: false,
      payment_title: '鉴证咨询服务*咨询费',
      columns: ['鉴证咨询服务*咨询费', '生活服务*文化服务', '生活服务*创意服务']
    }
  },

  created() {

  },
  methods: {
    onSubmit(form) {
      console.log('submit', form)
      const params = {
        payment_id: this.$route.query.payment_id,
        payment_title: this.payment_title,
        type: form.invoiceType,
        company_name: form.invoiceTitle,
        // company_tax: form.dutyNo,
        company_bank: form.bank,
        company_no: form.bankAccount,
        company_telphone: form.telphone,
        company_address: form.address
      }
      this.invoiceType === '2' && (params.company_tax = form.dutyNo)
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      this.$api.invoice_apply(params)
        .then(res => {
          this.$toast.success('提交申请成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('提交申请失败')
          console.error(err)
        })
    },
    onConfirm(value) {
      this.payment_title = value
      this.showPicker = false
    }
  }
}
</script>

<style lang="less" scoped>

  .container{
    padding: 10px 0;
  }
</style>

